import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import ProductList from 'components/ProductList';
import ProductListIsrael from 'components/ProductListIsrael';

const BusinessWorldHomeSection = () => {
  const { t } = useTranslation('home');
  const { domainLocationDisplayName, partner } = useContext(InternationalizationContext);

  const title = <span>{t('section_7.title', { domainLocationDisplayName })}</span>;

  return <>{partner.isIsrael ? <ProductListIsrael title={title} /> : <ProductList title={title} />}</>;
};

export default BusinessWorldHomeSection;
