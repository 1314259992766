import React, { useContext } from 'react';

import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Column from 'components/_designSystem/_layout/Column';
import Container from 'components/_designSystem/_layout/Container';
import Grid from 'components/_designSystem/_layout/Grid';
import BigBlueButton from 'components/BigBlueButton';
import BusinessHomeSection from 'components/BusinessHomeSection';
import BusinessWorldHomeSection from 'components/BusinessWorldHomeSection';
import DataAndToolsSection from 'components/DataAndToolsSection';
import DUNSRegistered from 'components/DUNSRegistered';
import { ExploreByIndustry } from 'components/ExploreByIndustry';
import { FeaturedCompanies } from 'components/FeaturedCompanies';
import SearchBar from 'components/SearchBar';
import SearchImage from 'public/images/search_man_illustration.png';

import styles from './Home.module.scss';

const Home = () => {
  const { t } = useTranslation('home');

  const { partner, getTranslated, domainLocationDisplayName } = useContext(InternationalizationContext);

  return (
    <>
      <div className={`${styles.home__search_container}`}>
        <Container>
          <Grid className="md:justify-items-center">
            <Column col={12} md={5} className="md:col-start-2 md:justify-self-auto">
              <h1 className="mt-0 lg:mt-12 text-4xl lg:text-5xl font-bold text-white">
                {`${t('where you find suppliers')}, `}
                {`${t('and buyers find you')}.`}
              </h1>
              <p className={styles.home__searchbar_subtitle}>
                {t('section_1.subtitle_1')} <b>{t('section_1.subtitle_2')}</b>{' '}
                {t('section_1.subtitle_3', { domainLocationDisplayName })}
              </p>
              <SearchBar />
              <div className={styles.home__powered}>
                <div className={styles.home__powered_text}>{t('section_1.powered')}</div>
                <Image
                  src={getTranslated(partner.logoWhite)}
                  alt={getTranslated(partner.name)}
                  priority
                  width={60}
                  height={24}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </div>
            </Column>
            <Column col={12} md={5} className={`md:justify-self-auto ${styles.home__search_img}`}>
              <Image
                src={SearchImage}
                alt="Search illustration"
                height={490}
                width={443}
                priority
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            </Column>
          </Grid>
        </Container>
      </div>
      <Container>
        <ExploreByIndustry />
      </Container>
      <Container>
        <BusinessHomeSection />
      </Container>
      <Container>
        <FeaturedCompanies />
      </Container>
      <Container>
        <DUNSRegistered />
      </Container>
      <Container>
        <DataAndToolsSection />
      </Container>
      <Container>
        <BusinessWorldHomeSection />
      </Container>
      <Container>
        <BigBlueButton href="/list-my-business">
          <div className={styles.button__text}>
            {t('section_8.button_1')}
            <div className={styles.button__text__bold}>{t('section_8.button_2')}</div>
          </div>
        </BigBlueButton>
      </Container>
    </>
  );
};

export { Home };
