import React, { useContext } from 'react';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '_ui/_common/Button';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Column from 'components/_designSystem/_layout/Column';
import Grid from 'components/_designSystem/_layout/Grid';
import styles from 'components/DataAndToolsSection/DataAndToolsSection.module.scss';
import HomeFinance from 'public/images/finance-solutions.svg';
import BackgroundBlob from 'public/images/Home-Vector-Cial.png';
import HomeSalesAndMarketing from 'public/images/sales-marketing.svg';
import HomeThirdPartyRisk from 'public/images/thrid-party-risk.svg';
import { getExternalUrl } from 'utils/externalLinks';

const DataAndToolsSection = () => {
  const { t } = useTranslation('home');
  const { language, getTranslated, partner } = useContext(InternationalizationContext);

  return (
    <Grid className={styles.container}>
      <Column col={12} md={6}>
        <div className="relative md:pl-16">
          <div className={styles.img__vector}>
            <Image
              className={styles.img__vector}
              src={BackgroundBlob}
              alt=""
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </div>
          <div className={styles.box}>
            <div className={styles.box__title}>
              <Image
                className={styles.box__image}
                src={getTranslated(partner.logo)}
                alt={`${getTranslated(partner.name)} logo`}
                width={227}
                style={{
                  maxWidth: '110px',
                  height: '45px',
                  objectFit: 'contain',
                }}
              />
            </div>
            <p className={styles.box__text}>
              {t('section_6.left.text', {
                partnerName: getTranslated(partner.name),
                partnerRegion: getTranslated(partner.region),
              })}
            </p>
            <div className={styles.footer__buttons}>
              <div className={styles.button}>
                <LinkButton
                  externalLink
                  href={getExternalUrl({ section: 'home', language, addSource: true })}
                  data-testid="data_tools--link_button"
                >
                  {t('section_6.left.button', { partnerName: getTranslated(partner.name) })}
                  <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                </LinkButton>
              </div>
            </div>
          </div>
          <Grid cols={4} lg={3} gap className={styles.container__info__box}>
            <Column col={2} lg={1}>
              <div className={styles.info__box}>
                <div className={styles.info__box__title}>330M+</div>
                <div className={styles.info__box__text}>{t('section_6.left.card_1')}</div>
              </div>
            </Column>
            <Column col={2} lg={1}>
              <div className={styles.info__box}>
                <div className={styles.info__box__title}>20,000+</div>
                <div className={styles.info__box__text}>{t('section_6.left.card_2')}</div>
              </div>
            </Column>
            <Column col={4} lg={1}>
              <div className={styles.info__box}>
                <div className={styles.info__box__title}>5M+</div>
                <div className={styles.info__box__text}>{t('section_6.left.card_3')}</div>
              </div>
            </Column>
          </Grid>
        </div>
      </Column>
      <Column col={12} md={6}>
        <div className={styles.right__container__tools}>
          <h2 className={styles.right__section__title}>{t('section_6.right.title')}</h2>
          <Grid className={styles.right__container__item}>
            <Column col={3} className={styles.img__tools}>
              <Image
                width={60}
                height={60}
                className={styles.img__tools}
                src={HomeFinance}
                alt="Finance"
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Column>
            <Column col={9}>
              <div className={styles.right__section__container__title}>{t('section_6.right.subtitle_1')}</div>
              <p className={styles.right__section__container__text}>{t('section_6.right.text_1')}</p>
            </Column>
          </Grid>
          <Grid className={styles.right__container__item}>
            <Column col={3} className={styles.img__tools}>
              <Image
                width={60}
                height={60}
                className={styles.img__tools}
                src={HomeThirdPartyRisk}
                alt="Third party risk"
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Column>
            <Column col={9}>
              <div className={styles.right__section__container__title}>{t('section_6.right.subtitle_2')}</div>
              <p className={styles.right__section__container__text}>{t('section_6.right.text_2')}</p>
            </Column>
          </Grid>
          <Grid className={styles.right__container__item}>
            <Column col={3} className={styles.img__tools}>
              <Image
                width={60}
                height={60}
                className={styles.img__tools}
                src={HomeSalesAndMarketing}
                alt="Sales and marketing"
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Column>
            <Column col={9}>
              <div className={styles.right__section__container__title}>{t('section_6.right.subtitle_3')}</div>
              <p className={styles.right__section__container__text}>{t('section_6.right.text_3')}</p>
            </Column>
          </Grid>
        </div>
      </Column>
    </Grid>
  );
};

export default DataAndToolsSection;
