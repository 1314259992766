import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import BannerImage from 'components/_images/BannerImage';
import ProfileImage from 'components/_images/ProfileImage';

import styles from './CompanyBannerCard.module.scss';

const propTypes = {
  company: PropTypes.object,
  children: PropTypes.node,
  bannerWidth: PropTypes.number,
  bannerHeight: PropTypes.number,
  bannerClassName: PropTypes.string,
  logoWidth: PropTypes.number,
  logoHeight: PropTypes.number,
  logoClassName: PropTypes.string,
};

const CompanyBannerCard = ({
  company,
  children,
  bannerWidth = 400,
  bannerHeight = 170,
  bannerClassName = styles.profile_background,
  logoWidth = 100,
  logoHeight = 100,
  logoClassName = styles.profile_image_logo,
}) => {
  const { getTranslated } = useContext(InternationalizationContext);
  const [isLoadingBanner, setIsLoadingBanner] = useState(true);

  return (
    <>
      <div className={styles.images}>
        <BannerImage
          path={company?.background}
          loading={isLoadingBanner}
          onLoad={() => setIsLoadingBanner(false)}
          alt={getTranslated({
            en: `${getTranslated(company, 'legalName')} background image`,
            es: `Imagen de fondo de ${getTranslated(company, 'legalName')}`,
            br: `Imagem de fundo do ${getTranslated(company, 'legalName')}`,
          })}
          width={bannerWidth}
          height={bannerHeight}
          className={bannerClassName}
        />
        <ProfileImage
          path={company?.logo}
          className={logoClassName}
          alt={getTranslated({
            en: `${getTranslated(company, 'legalName')} logo`,
            es: `Logotipo de ${getTranslated(company, 'legalName')}`,
            br: `Logotipo do ${getTranslated(company, 'legalName')}`,
          })}
          width={logoWidth}
          height={logoHeight}
        />
      </div>
      {children}
    </>
  );
};

CompanyBannerCard.propTypes = propTypes;

export default CompanyBannerCard;
