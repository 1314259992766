import React, { useContext } from 'react';

import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '_ui/_common/Button';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import styles from 'components/DUNSRegistered/DUNSRegistered.module.scss';
import Vector from 'public/images/Vector_Duns.png';
import { getExternalUrl } from 'utils/externalLinks';

const DUNSRegistered = () => {
  const { t } = useTranslation('home');
  const { language, getTranslated, partner } = useContext(InternationalizationContext);

  return (
    <div className={styles.duns_registered__container}>
      <div className={styles.duns_registered__content_container}>
        <h2 className={styles.duns_registered__title}>{getTranslated(partner.seal)}</h2>
        {partner.isIsrael ? (
          <p className={styles.duns_registered__content}>{t('section_5.il_text')}</p>
        ) : (
          <p className={styles.duns_registered__content}>{t('section_5.text')}</p>
        )}
        <div className={styles.sidebar_button__order_report}>
          <LinkButton
            externalLink
            href={getExternalUrl({
              section: 'salesMarketingDuns',
              language,
            })}
            data-testid="duns_registered--link_button"
          >
            {t('section_5.button', { partnerSeal: getTranslated(partner.seal) })}
          </LinkButton>
        </div>
      </div>
      <div className={styles.duns_registered__img_container}>
        <div className={partner.isIsrael ? styles.vector_israel : styles.vector}>
          {!partner.isIsrael && (
            <Image
              className={styles.vector__duns_registered__img}
              src={Vector}
              alt="Duns Registered badge"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          )}
        </div>
        <Image
          className={partner.isIsrael ? styles.duns_credibility__img : styles.duns_registered__img}
          src={getTranslated(partner.badge)}
          alt={getTranslated(partner.seal)}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </div>
    </div>
  );
};

export default DUNSRegistered;
