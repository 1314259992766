import React, { useContext } from 'react';

import { NextSeo } from 'next-seo';
import { useTranslation } from 'react-i18next';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { RouterContext } from 'components/_context/RouterContext';
import Layout from 'components/_layouts/Layout';
import { Home } from 'components/Home';
import { enhanceServerSideProps } from 'utils/enhanceServerSideProps';

const Index = () => {
  const { t } = useTranslation('seo');
  const { router, isFallback } = useContext(RouterContext);
  const { getMetaTags, domainLocationDisplayName } = useContext(InternationalizationContext);

  if (isFallback) {
    return <></>;
  }

  const { canonical, languageAlternates } = getMetaTags(router.asPath);

  return (
    <Layout hideSearch>
      <NextSeo
        titleTemplate="%s"
        title={t('home.titleSeo', { domainLocationDisplayName })}
        description={t('home.descriptionSeo', { domainLocationDisplayName })}
        canonical={canonical}
        openGraph={{
          url: canonical,
        }}
        languageAlternates={languageAlternates}
      />
      <Home />
    </Layout>
  );
};

export const getServerSideProps = enhanceServerSideProps();

export default Index;
