import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const propTypes = {
  icon: PropTypes.object,
  data: PropTypes.string,
  className: PropTypes.string,
};

const CompanyFeatures = ({ icon, data, className, ...rest }) => {
  if (!data) return null;

  return (
    <span className={className}>
      <FontAwesomeIcon icon={icon} className="text-gray-600" />
      <span className="ml-2 text-sm text-gray-600" {...rest}>
        {data}
      </span>
    </span>
  );
};

CompanyFeatures.propTypes = propTypes;

export default CompanyFeatures;
