import React, { useContext } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { replaceDirection } from 'utils/directionFormat';

const propTypes = {
  name: PropTypes.string, // Name of icon (ex. 'arrow' from 'fa-solid fa-arrow')
  iconStyle: PropTypes.oneOf(['solid', 'brands', 'regular', 'fortawesome']), // Style of icon (ex. 'solid' is 'fa-solid fa-arrow')
  className: PropTypes.string,
};

const Icon = ({ name, iconStyle = 'solid', className }) => {
  const { direction } = useContext(InternationalizationContext);

  const iconDirectionName = name ? replaceDirection(name, direction) : '';

  const iconClass = classNames({
    [`fa-${iconStyle} fa-${iconDirectionName}`]: iconStyle !== 'fortawesome',
    [`icon icon-${iconDirectionName}`]: iconStyle === 'fortawesome',
    [className]: className,
  });

  return <i className={iconClass} />;
};

Icon.propTypes = propTypes;

export default Icon;
